html {
  body {
    .menu-container {
      display: block;
      height: 100%;
      overflow-y: auto;

      .menu-accordion {
        display: block;
        width: 100%;

        .mat-expansion-panel {
          border-radius: 0 !important;
          background-color: transparent !important;
          box-shadow: none !important;
          margin: 0 !important;

          .mat-expansion-panel-header {
            padding: 0 !important;
            font-size: 0.75rem;
            font-weight: 400;
            background-color: transparent !important;
            height: max-content;

            .mat-content {
              margin: 0;
            }

            &[aria-expanded="true"] {
              .icon-button {
                transform: rotate(-180deg);
              }
            }
          }

          .mat-expansion-panel-body {
            padding: 0;
            background-color: rgba(0, 0, 0, 0.0375);
          }
        }

        &.no-background {
          .mat-expansion-panel {
            .mat-expansion-panel-body {
              background-color: transparent;
            }
          }
        }

        .icon-button {
          width: 1.5rem;
          height: 1.5rem;
          padding: 0.25rem;
          mat-icon {
            font-size: 1rem;
            width: 1rem;
            height: 1rem;
          }
        }

        .item-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1rem;

          > span {
            flex: 1;
            padding: 0.5rem 0;
            font-size: 0.75rem;
            max-width: calc(100% - 2rem);
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.025);
          }

          .actions {
            ul {
              padding: 0;
              margin: 0;
              display: flex;
              gap: 0.25rem;

              .icon-button:hover {
                background-color: rgba(0, 0, 0, 0.075);
              }
            }
          }
        }

        .item.multi {
          display: flex;
          align-items: center;
          padding: 0rem 1rem;
          gap: 0.5rem;
          width: 100%;

          &:hover {
            background-color: rgba(0, 0, 0, 0.025);
          }

          span.label {
            flex: 1;
            padding: 0.5rem 0;
            font-size: 0.75rem;
          }

          .icon-button:hover {
            background-color: rgba(0, 0, 0, 0.075);
          }
        }
      }
    }
  }
}
