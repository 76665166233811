html {
  body {
    #snackbar-overlay-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10000;
      pointer-events: none;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 12px;
      flex-direction: column-reverse;
      gap: 1rem;
      overflow: hidden;

      & > * {
        pointer-events: all;
        min-width: 350px;
      }
    }
  }
}
