html {
  body {
    #tooltip-overlay-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9000;
      pointer-events: none;

      & > * {
        pointer-events: all;
        visibility: hidden;
        display: block;
        position: absolute;
        width: max-content;
        max-width: 50%;
      }

      .tooltip-arrow {
        &::before {
          content: "";
          position: absolute;
          display: inline-block;
          background-color: inherit;
        }

        &.top {
          margin-top: -6px;
          &::before {
            width: 16px;
            height: 20px;
            clip-path: polygon(50% 30%, 0 0, 100% 0);
            bottom: -20px;
          }
        }
        &.bottom {
          margin-top: 6px;
          &::before {
            width: 16px;
            height: 20px;
            clip-path: polygon(50% 70%, 0% 100%, 100% 100%);
            top: -20px;
          }
        }
        &.start {
          margin-left: -6px;
          &::before {
            width: 20px;
            height: 16px;
            clip-path: polygon(30% 50%, 0 0, 0 100%);
            right: -20px;
          }
        }
        &.end {
          margin-left: 6px;
          &::before {
            width: 20px;
            height: 16px;
            clip-path: polygon(70% 50%, 100% 0, 100% 100%);
            left: -20px;
          }
        }

        &.hor-start::before {
          left: 8px;
        }
        &.hor-end::before {
          right: 8px;
        }
        &.hor-center::before {
          left: 50%;
          transform: translateX(-50%);
        }
        &.ver-start::before {
          top: 8px;
        }
        &.ver-end::before {
          bottom: 8px;
        }
        &.ver-center::before {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
