@page {
  size: 21cm 29.7cm;
}

@media print {
  html {
    background: transparent;
    body {
      overflow: visible;
      width: 21cm;

      .mat-accordion {
        &.clean {
          .mat-expansion-panel {
            .mat-expansion-panel-content {
              .mat-expansion-panel-body {
                margin-left: 0px;
                padding-left: 0px;
              }
            }
          }
        }

        &.compact {
          .mat-expansion-panel {
            .mat-expansion-panel-content {
              margin-left: 0px;
              padding-left: 0px;
            }
          }
        }
      }

      input[type="radio"]:not(:checked) {
        display: none !important;
      }

      .field-prefix {
        input,
        .fake-input,
        textarea,
        mat-select,
        .html-container,
        .link-container {
          background: none;
          border: 0px !important;
          box-shadow: none;
          padding: 0;
          padding-left: 5px;
          font-size: 13px;

          &[type="date"] {
            padding: 0;
          }

          .mat-mdc-select-arrow-wrapper {
            display: none;
          }

          &::-webkit-calendar-picker-indicator {
            display: none;
          }

          &::-webkit-datetime-edit {
            font-size: 13px;
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
